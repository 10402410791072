import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { Avatar } from "@material-ui/core";
import solitaireGuruLogo from "../../images/game/5f5b691eb75dc0c7b89b2492.png";
import wowGuruLogo from "../../images/game/601a6443b3e245291110ac7a.png";
import wowLogo from "../../images/game/wowLogo.png";
import wowSearch from "../../images/game/WowSearchLogo.png";
import colorBump3D from "../../images/game/ColorBump3DLogo.png";
import hunterAssassin2 from "../../images/game/HA2Logo.png";
import endCardApp from "../../images/game/EndCardAppIcon.png";
import atlasGame from "../../images/game/AtlasGameAppIcon.png";
import wordLetter from "../../images/game/wordLetter1.png";
import ballsMaster from "../../images/game/ballsMasterAppIcon.png";
import ballBlast from "../../images/game/ballBlastAppIcon.png";
import tapOnTime from "../../images/game/tapOnTime.png";
import gameOfWordsLogo from "../../images/game/gameOfWordsLogo.png";
import nameTheActivityLogo from "../../images/game/nameTheActivity.png";
import madSmashLogo from "../../images/game/madSmash.png";
import wordSquareLogo from "../../images/game/wordSquare.png";
import videoGameHtmlLogo from "../../images/game/VideoGameHTML.png";
import atlasGameHtmlLogo from "../../images/game/AtlasGameHTML.png";
import crosswordPlayableLogo from "../../images/game/CrosswordAppIcon.png";
import crosswordJamLogo from "../../images/game/CwJamAppIcon.png";
import wordSquarePlaySimpleLogo from "../../images/game/wordSquare.png";
import wordWarsLogo from "../../images/game/WWAppIcon.png";
import anagramLogo from "../../images/game/AnagramAppIcon.png";
import zombeesAppIcon from "../../images/game/zombeesAppIcon.jpg";
import sundaySolitaireLogo from "../../images/game/SundaySolitaireLogo.png";
import wordSurfLogo from "../../images/game/wordSurfAppIcon.png";
import wordSearchPsLogo from "../../images/game/wordSearchPsAppIcon.png";
import scratchHtmlLogo from "../../images/game/scratchGameLogo.png";
import wizardHeroLogo from "../../images/game/wizardHeroLogo.png";
import differencesLogo from "../../images/game/differencesLogo.png";
import tileMatchPsLogo from "../../images/game/tileMatchLogo.png";
import tilesPs2248Logo from "../../images/game/2248_tileMatch.jpeg";
import bloxieLogo from "../../images/game/bloxie.png";
import blockBusterLogo from "../../images/game/blockBuster.jpg";
import endCardHtmlLogo from "../../images/game/html5.png";
import findHiddenObjectsLogo from "../../images/game/findHiddenObjectLogo.png";
import hiddenTilesLogo from "../../images/game/hiddenTilesLogo.png";
import wordRollLogo from "../../images/game/wordRoll.png";
import jigsawLogo from "../../images/game/jigsaw.png";
import html5Logo from "../../images/game/html5.png";
import crosswordSearchLogo from "../../images/game/crosswordSearchLogo.png";
import e_comLogo from "../../images/game/e_comLogo.png";
import matchVillainLogo from "../../images/game/matchVillainLogo.png";
import wordhaneLogo from "../../images/game/wordhaneLogo.png";
import sortOutLogo from "../../images/game/sortOutLogo.png";

import _ from "lodash";
import { getServerUri } from "../modules/uriUtility";

const useStyles = makeStyles((theme) => ({
  gamePickerDiv: { display: "flex", flexDirection: "column", justifyContent: "flex-start", flex: 1 }
}));

export let images = {
  "5f5b691eb75dc0c7b89b2492": solitaireGuruLogo,
  "601a6443b3e245291110ac7a": wowGuruLogo,
  "603ce43ea91fee82f50334d3": wowLogo,
  "605cc7b259a203d77244525c": wowSearch,
  "6079680d222d9801f96bab74": colorBump3D,
  "614081de93ddb73076a1538a": hunterAssassin2,
  "619e14fb68af90fbc06a4345": endCardApp,
  "61af33d3222f4485886da806": atlasGame,
  "61fd1d864e862442e1bd67db": wordLetter,
  "62581d4558d6dd7853a161e8": ballsMaster,
  "625e7d9c152625add904a679": ballBlast,
  "6266a66a152625add904bce8": tapOnTime,
  "6267bb9ee12f014cd14b6c9f": gameOfWordsLogo,
  "627baaf241f6066717ff413f": nameTheActivityLogo,
  "62864cd341f6066717ff801e": madSmashLogo,
  "629e01961f70c95b7fd67b3a": wordSquareLogo,
  "62a09e2041f6066717fff686": videoGameHtmlLogo,
  "62b9a830cc0ad8e0a9eaa81c": atlasGameHtmlLogo,
  "62e8c71357d6958461cbe9d6": crosswordPlayableLogo,
  "62f50cd857d6958461cc54d3": crosswordJamLogo,
  "62f6121457d6958461cc7ef0": wordSquarePlaySimpleLogo,
  "6318807957d6958461cd5fc1": wordWarsLogo,
  "632aca17d12f27f917de59ec": anagramLogo,
  "635fe93ded1431b3f7d2215c": zombeesAppIcon,
  "6371ed43dd2735639e05b6ab": sundaySolitaireLogo,
  "63f8a26aa2afbaeb5f803de3": wordSurfLogo,
  "63ff24d8f1b9d0fb8487223f": wordSearchPsLogo,
  "640060027d19caf1ca799d1f": scratchHtmlLogo,
  "649a7061f01fdda7a6d44598": wizardHeroLogo,
  "656f16ee7e3091d6e2607a1d": differencesLogo,
  "65a6576f99010ea68565fb77": tileMatchPsLogo,
  "65cb16fc897ec6f9db711354": tilesPs2248Logo,
  "65fae6d540aceaef5d88bd51": bloxieLogo,
  "661504b840aceaef5d8cba1f": blockBusterLogo,
  "6615087940aceaef5d8cbace": endCardHtmlLogo,
  "6627943e40aceaef5d8eec81": findHiddenObjectsLogo,
  "66333f70937d97accbf169e1": hiddenTilesLogo,
  "6644b6a2937d97accbf26c30": wordRollLogo,
  "66f168190255a9f8088f2134": jigsawLogo,
  "66fea2d30255a9f80892a1ec": html5Logo,
  "673dd19192e99634ba8d569e": crosswordSearchLogo,
  "673f847e92e99634ba8f7826": e_comLogo,
  "6744875ec1b59287554cde94": matchVillainLogo,
  "67603fb092e99634ba91ed45": wordhaneLogo,
  "6762dc9292e99634ba92f290": sortOutLogo
};

/**
 *
 * @param props.items {Array}
 * @param props.setSelectedItem {Function}
 * @returns {JSX.Element}
 * @constructor
 */
export default function SelectorGame(props) {
  const [value, setValue] = useState(_.get(props, "value"));
  const classes = useStyles();
  if (props.items === undefined) return null;
  return (
    <div id="selectorGame" className={classes.gamePickerDiv}>
      {props.items.map((game) => {
        return (
          <div
            id={game.title}
            key={game._id}
            style={{ backgroundColor: game._id === value ? "hsla(128,0%,50%,0.5)" : "hsla(128,0%,50%,0)" }}
          >
            <Avatar
              id="selectorGame_avatar"
              className={props.classes.avatarSmall}
              key={String(game._id)}
              src={images[String(game._id)]}
              title={game.title}
              onClick={(event) => {
                if (!_.isNil(props.selectedVersion) && props.userPermissions["update"]) {
                  const createdLanguages = _.get(props, "selectedVersion.createdLanguages")
                    ? _.get(props, "selectedVersion.createdLanguages")
                    : ["en"];

                  fetch(getServerUri("version/updateOne"), {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams({
                      accessToken: localStorage.getItem("session.accessToken"),
                      versionID: String(_.get(props, "selectedVersion._id")),
                      gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
                      recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
                      createdLanguages: JSON.stringify(createdLanguages)

                      //isBeingUpdated: String(false)
                    })
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        console.log({ message: "version updated" });
                        const newVersion = _.cloneDeep(props.selectedVersion);
                        //_.set(newVersion, 'isBeingUpdated', false);
                        const newVersions = _.cloneDeep(props.versions);
                        for (let i = 0; i < newVersions.length; i++)
                          if (newVersions[i]._id === props.selectedVersion._id) {
                            newVersions[i] = newVersion;
                          }
                        props.setVersions(newVersions);
                        setValue(game._id);
                        props.setValue(game);
                      } else if (response.status === 401) {
                        //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                        response
                          .text()
                          .then((textResponse) => {
                            console.log({ message: "error updating", textResponse });
                          })
                          .catch(console.error);
                        setValue(game._id);
                        props.setValue(game);
                      } else {
                        setValue(game._id);
                        props.setValue(game);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                } else {
                  setValue(game._id);
                  props.setValue(game);
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
